.main_dealer_container {
    /* background: linear-gradient(to bottom, #c8d9e8, #ffffff); */
    font-family: 'Montserrat', sans-serif;


}


.dealer_text_para {
    font-size: 18px;
    /* font-weight: 400; */
    font-family: 'Roboto', sans-serif;
    word-spacing: 2px;
    /*word-break: break-all;*/
    letter-spacing: 0.3px;
}


@media only screen and (max-width: 600px) {
    .dealer_text_heading {
        font-size: 15px;
    }
}


@media only screen and (max-width: 600px) {
    .dealer_text_para {
        font-size: 15px;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;
        word-spacing: 1px;
    }
}




@media only screen and (max-width: 600px) {
    .dealer_text_heading_2 {
        font-size: 17px;
    }
}


.dealer_span_text {
    font-size: 30px;
    color: #17aab5;
}

@media only screen and (max-width: 600px) {
    .dealer_span_text {
        font-size: 20px;
    }
}

.dealer_child_box {
    display: flex;
    justify-content: center;
    margin-top: 70px;
    font-family: 'Montserrat', sans-serif;

}

@media only screen and (max-width: 600px) {
    .dealer_child_box {
        margin-top: 10px;
        margin-left: 5px;
        margin-right: 5px;
    }
}

.dealer_text_container {
    width: 1170px;
    height: fit-content;
    /* background-color: white; */
    /* border-radius: 20px; */
    padding: 30px;
    font-family: 'Montserrat', sans-serif;
    /* text-align: center; */

}

@media only screen and (max-width: 600px) {
    .dealer_text_container {
        width: 100%;
        height: fit-content;
        padding: 10px;
        border-radius: 10px;
    }
}

.dealer_text_heading {
    font-size: 22px;
}