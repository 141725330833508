.main_investor_containner {
    margin-top: 40px;
    font-family: 'Montserrat', sans-serif;

}

@media only screen and (max-width: 600px) {
    .main_investor_containner {
        margin-top: 10px;
        margin-left: 5px;
        margin-right: 5px;
    }
}

.investor_button {
    outline: none;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    color: #1961ac;
}

.investor_row_data {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    border-bottom: 2px solid #f7f7f7;
    padding-bottom: 10px;
    width: 100%;
    justify-content: space-between;
    font-family: 'Montserrat', sans-serif;


}


.investor_row_data-fina {

    justify-content: center;
    align-items: center;
    margin-top: 40px;
    border-bottom: 2px solid #f7f7f7;
    /* padding-bottom: 0px; */
    width: 100%;
    justify-content: space-between;
    font-family: 'Montserrat', sans-serif;
}




@media only screen and (max-width: 600px) {
    .text_data_investor {
        font-size: 13px;
        width: 300px;
    }
}


@media only screen and (max-width: 600px) {
    .investor_row_data {
        font-size: 13px;
    }




}


.main-an {
    display: flex;
    justify-content: center;
    margin-top: 100px;
}

.main-an-box {
    background-color: #fafafa;
    margin-left: 100px;
    margin-right: 100px;
    width: 1200px;
    min-height: 500px;
    max-height: fit-content;
    padding: 20px;
}

.select-class {
    width: 200px;
    height: 47px;
    outline: none;
    padding: 10px;
    /* border: none; */

}

.pdf-clas {
    display: flex;
    align-content: center;
    gap: 10px;
    padding: 5px;
    background-color: white;
    border: solid 1px;
    border-color: gray;
}

.pdf-clas-2 {
    display: flex;
    align-content: center;
    gap: 10px;
    padding: 5px;
    background-color: white;
    border: solid 1px;
    border-color: gray;
}

@media only screen and (max-width: 768px) {
    .main-an {
        margin-top: 20px;
    }

    .main-an-box {
        background-color: #fafafa;
        margin-left: 10px;
        margin-right: 10px;
        width: 100%;
        padding: 20px;
    }

    .select-class {
        width: 150px;
        height: 47px;
        outline: none;
        padding: 10px;
        /* border: none; */

    }

    .h2-text {
        font-size: 18px;
    }

}