.ring {
    position: fixed;
    top: 0;
    left: 0;
    width: 42px;
    height: 42px;
    border: 3px solid #1961ac;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    /* -webkit-transition-duration: 100ms; */
    /* transition-duration: 100ms; */
    /* -webkit-transition-timing-function: ease-out; */
    /* transition-timing-function: ease-out; */
    /* will-change: width, height, transform, border; */
    z-index: 999;
    pointer-events: none;
    animation: blink 1000ms infinite;
    transition: ease-in-out 0.5;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

}




@keyframes blink {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}