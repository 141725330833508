/* styles.css */
.child_dealer_container {
    display: flex;
    gap: 30px;
    margin-top: 40px;
    font-family: 'Roboto', sans-serif;


}

@media screen and (max-width: 600px) {
    .child_dealer_container {
        display: none;
    }
}

.main_solution_card_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 40px;
    font-family: 'Roboto', sans-serif;


}

.card {
    position: relative;
    width: 269px;
    height: 360px;
    border: 1px solid #ddd;
    overflow: hidden;
    /* box-shadow: 10px 10px 20px rgba(128, 128, 128, 0.2), -5px -5px 10px rgba(192, 192, 192, 0.5) inset; */
    margin-bottom: auto;
    border-radius: 5%;
    object-fit: cover;
    object-fit: fill;
    font-family: 'Roboto', sans-serif;


    /* transition: transform 0.3s ease-in-out; */
}




.content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(25, 97, 172, 0.6);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    color: white;
    opacity: 0;
    font-family: 'Roboto', sans-serif;


    /* transition: opacity 0.9s; */
}





.dealer_heading_text {
    color: white;
    font-size: 18px;
    text-align: center;
    /* background-color: rgba(25, 170, 175); */
    background-color: #1961ac;
    padding: 10px;
    width: 200px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0px;
    font-family: 'Roboto', sans-serif;
    /* transition: ease-in-out 0.1s; */
    /* transition: opacity 0.3s; */
    /* Uncomment this line */
    /* word-spacing: 1px; */
    /*letter-spacing: 0.2px;*/
}

/* @media screen and (max-width: 600px) {
    .dealer_heading_text {
        width: 150px;
        height: 50px;
        font-size: 13px;
        border-radius: 7px;

    }

} */



.hide-text {
    opacity: 0 !important;
}

.card:hover .dealer_heading_text {
    opacity: 0;
}

.card:hover .content {
    opacity: 1.1;
}

.know-more_card {
    display: flex;
    flex-direction: column;
}

.know-more-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 200px;
    text-align: center;
    background-color: white;
    /* color: rgba(25, 170, 175); */
    color: #1961ac;
    border: none;
    cursor: pointer;
    font-size: 17px;
    border-radius: 5px;
    margin-bottom: 40px;
    /* transition: background-color 0.3s; */
    font-family: 'Roboto', sans-serif;

}


/* @media screen and (max-width: 600px) {
    .know-more-btn {
        font-family: 13px;
        padding: 5px;
        width: 140px;
        margin-bottom: 50px;
    }
} */


.content_box-card {
    margin-bottom: 90px;
    width: auto;
    height: auto;
}

/* @media screen and (max-width: 600px) {
    .content_box-card {
        margin-bottom: 0;
    }
} */

.heading_card_content {
    font-size: 18px;
    margin-right: 140px;
    margin-bottom: 20px;
}

/* @media screen and (max-width: 600px) {
    .heading_card_content {
        font-size: 15px;
        margin-right: 20px;
        margin-bottom: 0;
        padding-top: 20px;
    }
} */



.card_content_text {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    color: white;
    word-spacing: 2px;
    letter-spacing: 0.2px;
    /*word-break: break-all;*/
}

/* for mobile responsive */


.mobile_dealer_heading_text {
    color: white;
    font-size: 15px;
    text-align: center;
    background-color: rgba(25, 170, 175, 1);
    padding: 10px;
    width: 150px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    bottom: 0;
    left: 10px;
    position: absolute;
    font-family: 'Roboto', sans-serif;


}

.mobile_child_dealer_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    display: none;
}

@media screen and (max-width: 768px) {
    .mobile_child_dealer_container {
        display: block;
    }
}

.mobile_child {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.card_mobile {
    width: 170px;
    height: 210px;
    border-radius: 10px;
    position: relative;
    object-fit: cover;
    object-fit: fill;
}



.solution_heading_text {
    /* font-size: 32px; */
    font-weight: 700;
    word-spacing: 2px;
    letter-spacing: 0.2px;
}

@media screen and (max-width: 600px) {
    .solution_heading_text {
        font-size: 20px;
    }
}