/* directer.css */

.main-about-sec-n {
    margin-left: 100px;
    margin-right: 100px;
    /*padding-top: 150px;*/
    padding-bottom: 50px;

}

.h2-text-new-b {
    width: 924px;
    height: 224px;
    font-size: 50px;
    font-weight: 700;
    word-spacing: 1px;
    letter-spacing: 0.2px;
    text-shadow: 10px 10px 10px white;

}

.p-text-new-ab {
    width: 924px;
    height: 69px;
    font-size: 24px;
    word-spacing: 1px;
    letter-spacing: 0.2px;
    /* color: #7c7f83; */
    margin-top: 20px;
}

.heading-new-div-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

.heading-new-div {
    text-align: center;
    width: 70%;
}


.p-text-new-para {
    font-size: 20px;
    word-spacing: 1px;
    letter-spacing: 0.3px;
    /* color: #7c7f83; */
    /*word-break: break-all;*/
    margin: 0;
}

.tab-wrap-ab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 105px;
}

.div-text-w-ab {
    width: 46%;
    /* padding: 5px; */
}

.div-text-w-im {
    width: 40%;
    position: relative;
    display: block;
}

.vc-im {
    position: absolute;
    top: -150px;
    left: -100px;
}

.bg-box-ab {
    background-color: #1961ac;
    width: 400px;
    height: 400px;
    margin-left: 100px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px #1961ac;

}


.tec-im-ab {
    width: 420px;
    height: 440px;
    position: absolute;
    left: 40px;
    border-radius: 5px;
    top: -70px;
}





.main-tech-so {
    margin-left: 100px;
    margin-right: 100px;
}

.in-tech-so {
    background-color: #f7f8fa;
    width: 60%;
    height: 300px;
    margin-right: -260px;
    z-index: 999;
    padding: 20px;
    box-shadow: 0px 0px 10px lightgray;

}
.in-tech-sohome {
    background-color: #f7f8fa;
    width: 44%;
    height: 300px;
    margin-right: 208px;
    margin-left: 67px;
    z-index: 999;
    padding: 20px;
    box-shadow: 0px 0px 10px lightgray;

}
.tech-so-im {
    width: 58%;
    height: 350px;
    padding-left: 50px;
    display: block;
}

.main-tech-f {
    display: flex;
    align-items: center;
}


.in-tech-so-2 {
    background-color: #f7f8fa;
    width: 59%;
    height: 300px;
    margin-left: -180px;
    z-index: 999;
    padding: 20px;
    box-shadow: 0px 0px 10px lightgray;

}
.in-tech-so-2home {
    background-color: #f7f8fa;
    width: 44%;
    height: 300px;
    margin-left: -170px;
    z-index: 999;
    padding: 20px;
    box-shadow: 0px 0px 10px lightgray;

}

.tech-so-im-2 {
    width: 58%;
    height: 350px;
    display: block;
    /* padding-left: 50px; */
}


.bd-main {
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 100px;
    margin-bottom: 100px;
    display: block;
}

.bd-in-m {
    background-color: #dedee2;
    height: 350px;
    margin-top: 30px;
    padding: 20px;
    padding-top: 30px;
    /* border-radius: 10px; */
    display: flex;
    justify-content: center;
    /* align-items: center; */
    padding-left: 40px;
}


.h2-text-new-board {
    font-size: 50px;
    font-weight: 700;
    word-spacing: 1px;
    letter-spacing: 0.2px;
    text-shadow: 10px 10px 10px white;
}

.main_about_sg-fin {
    margin-left: 0;
    margin-right: 0;
    font-family: 'Roboto', sans-serif;

}

.about-text-box {
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
}


.div-text-w-im-mb {
    width: 100%;
    position: relative;
    display: none;
}


@media only screen and (max-width: 600px) {
    .main_about_sg-fin {

        margin-left: 5px;
        margin-right: 5px;

    }




    .in-tech-so {
        background-color: #f7f8fa;
        width: 100%;
        height: fit-content;
        margin-right: -260px;
        z-index: 999;
        padding: 20px;
        box-shadow: 0px 0px 10px lightgray;

    }

    .tech-so-im-2 {
        padding-left: -0px;
        display: none;
        /* padding-left: 50px; */
    }

    .bd-main {

        display: none;
    }

    .in-tech-so-2 {
        background-color: #f7f8fa;
        width: 100%;
        height: fit-content;
        margin-left: 0px;
        z-index: 999;
        padding: 20px;
        box-shadow: 0px 0px 10px lightgray;

    }

    .main-tech-so {
        margin-left: 10px;
        margin-right: 10px;
    }

    .about-text-box {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }

    .main-about-sec-n {
        margin-left: 10px;
        margin-right: 10px;
        padding-top: 90px;
        padding-bottom: 50px;

    }

    .tech-so-im {

        display: none;
    }

    .h2-text-new-b {
        width: 100%;
        height: 224px;
        font-size: 30px;
        font-weight: 700;
        word-spacing: 1px;
        letter-spacing: 0.2px;
        text-shadow: 10px 10px 10px white;

    }


    .tab-wrap-ab {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 200px;
    }

    .div-text-w-ab {
        width: 100%;
        /* padding: 5px; */
    }

    .div-text-w-im {
        display: none;
    }

    .div-text-w-im-mb {
        width: 100%;
        position: relative;
        display: block;
    }

    .tec-im-ab {
        width: 90%;
        height: 300px;
        position: absolute;
        left: 0px;
        border-radius: 5px;
        top: -20px;
    }

    .bg-box-ab {
        background-color: #1961ac;
        width: 85%;
        height: 300px;
        margin-left: 40px;
        border-radius: 5px;
        box-shadow: 0px 0px 10px #1961ac;

    }


    .heading-new-div {
        text-align: center;
        width: 100%;
    }


    .h2-text-new-b {
        width: 100%;
        /* height: 224px; */
        font-size: 25px;
        font-weight: 700;
        word-spacing: 0.5px;
        letter-spacing: 0.2px;
        text-shadow: 10px 10px 10px white;

    }


}


.custom_background_dir {
    /* background-color: rgba(13, 132, 130, 1.0); */
    /* background-color: #1961ac; */

    /* border-radius: 10px; */
    color: white;
    font-family: 'Roboto', sans-serif;



}

.custom_background_dir-inside {
    /* border: solid 2px #1961ac; */
    background-color: white;
    /* border-radius: 10px; */
    margin: 10px;
    width: 580px;
    box-shadow: 0px 0px 10px rgb(169, 168, 168);

}

.dir_mission_swiper {
    width: 100%;
    height: 230px;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 15px;
    font-family: 'Roboto', sans-serif;
    color: black;



}
.h2-text-header1 {
    /*font-size: 65px; */
    /* font-weight: 600; */
    position: absolute;
    top: 200px;
    left: 100px;
    color: white;
    top: 50%;
    left: 49%;
    text-align: center;
}
@media only screen and (max-width: 600px) {
    .dir_mission_swiper {
        width: 100%;
        height: 420px;
        display: flex;
        flex-direction: column;
    }
}


.director-image_mission {
    border-radius: 100px;
    width: 128px;
    height: 128px;
    font-family: 'Roboto', sans-serif;
    border: #1961ac solid 2px;

}

.dir_heading_text {
    font-size: 25px;
    /* color: #1961ac; */
}

@media only screen and (max-width: 768px) {
    .dir_heading_text {

        font-size: 20px;
    }
}


.dir_para_text {
    font-size: 18px;
    /*word-break: break-all;*/
    word-spacing: 2px;
    letter-spacing: 0.2px;
    display: -webkit-box;
    /* For browser compatibility */
    -webkit-line-clamp: 3;
    /* Limit to three lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
}


@media only screen and (max-width: 768px) {
    .dir_para_text {

        font-size: 14px;
    }



}



.tech-solution {
    display: flex;
    flex-direction: row;
    gap: 60px;
    margin-top: 40px;
}

.fina-class-about {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 80px;
    margin-top: 50px;
}

.fina-class-about-inside {
    width: 400px;
    height: fit-content;
    padding: 20px;
    /* border-radius: 10px; */
    background-color: #1961ac;

}

.tech-solution-inside {
    width: 50%;
}


.dna-content-board-of {
    display: flex;
    align-items: center;
    gap: 20px;

}

.directer-b-main-p {
    background-color: #1961ac;
    margin-top: 50px;
    padding: 70px 0px 20px 0px;
}

.directer-b-main {
    margin-left: 100px;
    margin-right: 100px;
    /* margin-top: 100px; */
    background-color: white;
    border-radius: 10px;

}

.dire-b-text {
    width: 40%;
    font-size: 18px;
    padding: 10px;
}

.dire-b-text-2 {
    width: 60%;
}


.btn-class-dir {
    position: absolute;
    z-index: 999;
    bottom: 0px;
    right: 590px;

}






@media only screen and (max-width: 768px) {
    .tech-solution {
        display: flex;
        flex-direction: column;
    }

    .tech-solution-inside {
        width: 100%;
    }

    .fina-class-about {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        margin-top: 20px;
    }



    .fina-class-about-inside {
        width: 100%;

    }




    .dna-content-board-of {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

    }

    .directer-b-main {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 40px;
    }

    .dire-b-text {
        width: 100%;
        font-size: 18px;
    }

    .dire-b-text-2 {
        width: 100%;
        font-size: 18px;
    }

}


.know-btn-bord {
    outline: none;
    color: #1961ac;
    border: none;
    background-color: transparent;
}