.main_career_box {
    width: 1000px;
    height: 80px;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    font-family: 'Montserrat', sans-serif;


}

.text-car-wor {
    word-break: break-all;
    word-spacing: 2px;
    letter-spacing: 0.2px;
    width: 45%;
}

@media only screen and (max-width: 768px) {


    .text-car-wor {
        word-break: break-all;
        word-spacing: 2px;
        letter-spacing: 0.2px;
        width: 100%;
    }


    .main_career_box {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;
        padding-left: 10;
        padding-right: 10;
    }

    .main-life-box {
        margin-left: 10px;
        margin-right: 10px;
    }
}

.dropdown-container {
    position: relative;
    width: 350px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    /* background-color: #fff; */


}


@media only screen and (max-width: 600px) {
    .dropdown-header {
        width: 100%;
    }
}

.dropdown-header {
    width: 350px;
    padding: 10px;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 5px;
    font-family: 'Montserrat', sans-serif;

}

.dropdown-list {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    font-family: 'Montserrat', sans-serif;
    /* opacity: 1 !important; */

}

.dropdown-list li {
    padding: 10px;
    cursor: pointer;
    border-top: 1px solid #ccc;
    z-index: 1;
    background-color: #fff;
}

.dropdown-list li:hover {
    background-color: #f0f0f0;
}










/* Dropdown styling */
.dropdown-container {
    position: relative;
    display: inline-block;
}

.dropdown-header {
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ccc;
    user-select: none;
}

.arrow-icon {
    transition: transform 0.3s;
}

.arrow-icon.open {
    transform: rotate(90deg);
}

.dropdown-content {
    display: block;
    position: absolute;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    z-index: 1;
    width: 100%;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 5px;

}

.dropdown-content div {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
}

/* Remove border-bottom from the last child */
.dropdown-content div:last-child {
    border-bottom: none;
}

.main-life-box {
    margin-left: 100px;
    margin-right: 100px;
}

.main-life-box-inside {
    display: flex;
    /* gap: 50px; */
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    margin-top: 50px;
    margin-bottom: 80px;

}

.job-main-box {
    margin-top: 100px;
    margin-bottom: 100px;
}

.car-cl {
    width: 55%;
}

.h2-text-car {
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.1px;
    word-spacing: 2px;
    font-family: sans-serif;
    padding-top: 20px;
}


.main-life-box-section {
    margin-left: 100px;
    margin-right: 100px;
    background-color: rgba(25, 97, 172, 0.9);
    padding: 100px 20px 100px 20px;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

.tab-img-div {
    position: relative;
    width: 48%;
    height: 330px;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    /* To ensure child elements don't overflow */
}

.tab-img {
    width: 100%;
    height: auto;
    /* Adjusted to maintain aspect ratio */
    border-radius: 10px;
    transition: transform 0.3s ease;
    /* Adding transition for smooth scaling */
    object-position: center;
}

.tab-img-in-div {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    /* Initially hidden */
    transition: opacity 0.3s ease;
    /* Adding transition for smooth opacity change */
}

/* Hover effect */
.tab-img-div:hover .tab-img-in-div {
    opacity: 1;
    /* Show the overlay on hover */
}

.tab-img-div:hover .tab-img {
    transform: scale(1.1);
    /* Scale the image on hover */
}


.aerrow-div {
    /* position: absolute; */
    color: white;
    border: solid 2px white;
    padding: 20px;
    border-radius: 50%;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 43%;
    right: 50%;
    bottom: 50%;
}




/* Styling for the pop-up */
.popup {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent background */
    z-index: 999;
    /* Ensures the pop-up appears above other content */
}

.popup-content {
    width: 80%;
    /* max-width: 1000px; */
    /* Limiting maximum width */
    height: 500px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
}


.image-div {
    width: 330px;
    height: 420px;
}





@media only screen and (max-width: 768px) {


    .main-life-box {
        margin-left: 10px;
        margin-right: 10px;
    }


    .main-life-box-inside {
        display: flex;
        flex-direction: column;
        /* gap: 0px; */

    }

    .car-cl {
        width: 100%;
    }




    .main-life-box-section {
        margin-left: 10px;
        margin-right: 10px;
        background-color: rgba(25, 97, 172, 0.9);
        padding: 10px 20px 10px 20px;
        height: fit-content;

    }


    .tab-img-div {
        position: relative;
        width: 100%;
        height: fit-content;
        background-color: white;
        border-radius: 10px;
        overflow: hidden;
        /* To ensure child elements don't overflow */
    }



    .popup-content {
        width: 100%;
        /* max-width: 1000px; */
        /* Limiting maximum width */
        height: 500px;
        background-color: white;
        padding: 20px;
        border-radius: 10px;
    }


}