.main-position-box {
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 100px;
    display: block;

}


.main-position-box-mobile {
    display: none;
}


.p-text-posi {
    font-size: 18px;
    /* font-weight: 500; */
    /* padding-top: 8px; */
    word-spacing: 2px;
    /*word-break: break-all;*/
}

.span-text {
    color: #f68721;
}


@media only screen and (max-width: 768px) {
    .main-position-box {
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 30px;
        display: none;
    }

    .h2-text-posi {
        text-align: center;

    }


    .main-position-box-mobile {
        display: block;
        margin-top: 60px;
    }


    .container-position {
        position: relative;
        width: 100%;
    }






}






.main-card-box-posi {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    /* background-color: #13908a; */
    min-height: 600px;
}

.container-position {
    position: relative;
    width: 1100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px;
}

.container-position .card-position {
    position: relative;
    max-width: fit-content;
    height: 215px;
    background-color: #fff;
    /* margin: 30px 15px; */
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* box-shadow: 0 5px 20px rgba(19, 144, 138, 0.5); */
    background-color: #2e93eb;
    transition: 0.3s ease-in-out;
    border-radius: 15px;
}

.container-position .card-position:hover {
    height: 330px;
}

.container-position .card-position .image {
    position: relative;
    width: 200px;
    height: 200px;
    top: -60%;
    /* left: 10px; */
    /* box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2); */
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 1;
    border-radius: 50%;
    padding: 20px;
    /* background-color: rgba(19, 144, 138, 0.8); */
    /* 80% opacity */
}

.container-position .card-position .image img {
    max-width: 100%;
    padding: 10px;
    border-radius: 15px;


}

.container-position .card-position .content-posi {
    position: relative;
    top: -140px;
    /* padding: 10px 15px; */
    color: #111;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease-in-out;
    z-index: 1;
    color: white;
}

.container-position .card-position:hover .content-posi {
    visibility: visible;
    opacity: 1;
    transition-delay: 0.2s;
    /* padding-top: 70px; */
}

.h5-text-posi {
    text-align: center;
    padding-top: 25px;
    font-size: 20px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.3px;

}


.container-position .card-position:hover .h5-text-posi {
    display: none;
}



/* mobile responsive ===============================*/


.main-card-box-posi-mobi {
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 40px;

}

.container-position-mobi {
    position: relative;
    width: 100%;
    height: 300px;
    /* background-color: #299791; */

}

.image {
    width: 140px;
    height: 140px;
}

.card-position-mobi {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.content-posi {
    padding-top: 10px;
}