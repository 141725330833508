.main-choose {
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 50px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
    justify-content: space-between;
}

.main-choose-inside-2 {
    width: 100%;
    display: flex;
    justify-content: space-between;

}

.main-choose-inside {
    width: 100%;
}

.div-box-choose {
    width: 220px;
    height: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    border-radius: 10px;

}


.div-box-choose:hover {
    background-color: #1961ac;
    /* opacity: 0.6; */
    transition: ease-in-out 0.3s;
}


.icon-class-c {
    padding: 15px;
    width: 58px;
    border-radius: 10px;

}

@media screen and (max-width: 768px) {
    .main-choose {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 20px;
        margin-bottom: 100px;
        display: flex;
        flex-direction: column;
        gap: 50px;
        align-items: center;
        justify-content: space-between;
    }


    .main-choose-inside {
        width: 100%;
    }

    .main-choose-inside-2 {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 50px;
        text-align: center;
        align-items: center;
        justify-content: center;

    }

    .div-box-choose {
        width: 200px;
        height: 100px;


    }





}

.p-text-c {
    font-weight: 500;
    font-size: 18px;
    height: 20px;
    text-align: center;
    word-spacing: 1px;
    letter-spacing: 0.2px;
}