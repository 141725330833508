/* styles.css */

.navbar_menu {
    padding: 1rem;

}

.container_menu {
    max-width: 'fit';
    margin: 0 auto;
    display: flex;
    display: none;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
}

/* Hide the element on screens with a max-width of 767 pixels (assuming this is your mobile breakpoint) */
@media (max-width: 767px) {
    .container_menu {
        display: block;
    }
}


.menu-icon {
    font-size: 2rem;
    color: black;
    cursor: pointer;
}

.menu-list {
    display: flex;
    list-style: none;
    /* color: #ffffff; */
}

.menu-list-item {
    padding: 0.5rem;
    font-weight: bold;
    font-size: 17px;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.5px;



}

.responsive-menu {
    transition-duration: 300ms;
    display: flex;
    flex-direction: column;
    /* Set the direction to column */
    background-color: white;
    top: 62px;
    position: fixed;
    left: -100%;
    width: 100%;
    height: 100%;
    /* opacity: 1.2; */
    font-family: 'Roboto', sans-serif;



}

.responsive-menu.visible {
    left: 0;
    font-family: 'Roboto', sans-serif;

}





/* know more buttton */