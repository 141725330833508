.con-card-cl {
    width: 300px;
    height: 260px;
    background-color: #1961ac;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    box-shadow: 0px 4px 6px rgba(25, 97, 172, 1);
    border: solid 2px blue;

}

.two-b {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.main-con-card {
    display: flex;
    width: 50%;
    gap: 40px;

}

.main-con-cl {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    gap: 50px;
    margin-top: 30px;
}

.main-wraper-cl {
    margin-left: 100px;
    margin-right: 100px;
}
.single-footer-widget{
    margin-top: 30px; 
}

@media screen and (max-width: 520px) {
    .main-wraper-cl {
        margin-left: 10px;
        margin-right: 10px;
    }

    .main-con-cl {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 50px;
        margin-top: 40px;
    }

    .two-b {
        gap: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .main-con-card {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 20px;

    }


    .con-card-cl {
        width: 100%;
        height: 269px;
    }

}