.main-solution-text-box {
    margin-left: 100px;
    margin-right: 100px;
    margin-bottom: 100px;
}

.p-text-solu-text {
    font-size: 18px;
    word-break: break-all;
    word-spacing: 2px;
    text-align: center;
    letter-spacing: 0.2px;
}

@media screen and (max-width: 768px) {
    .main-solution-text-box {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px;

    }

    .p-text-solu-text {
        font-size: 15px;
        word-break: break-all;
        word-spacing: 2px;
        letter-spacing: 0.2px;
    }

}



.main-card-wrap-in {
    width: 400px;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    word-break: break-all;
    text-align: center;
}

.image-con {
    width: 150px;
}

.card-clas-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 350px;
    height: 400px;
    gap: 20px;
    border-radius: 10px;
    padding: 10px;

}

.card-clas-main-box {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    margin-top: 30px;
    margin-bottom: 40px;
}

.p-text-solu-text-para {
    height: 300px;
    font-size: 19px;
    /*word-break: break-all;*/
    word-spacing: 2px;
    text-align: center;
    letter-spacing: 0.2px;
}