button,
textarea,
input {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    letter-spacing: 1.4px;
}









.screen-header-button.close {
    background: #ed1c6f;
}

.screen-header-button.maximize {
    background: #e8e925;
}

.screen-header-button.minimize {
    background: #74c54f;
}

.screen-header-right {
    display: flex;
}

.screen-header-ellipsis {
    width: 3px;
    height: 3px;
    margin-left: 2px;
    border-radius: 8px;
    background: #999;
}

.screen-body {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

}

.screen-body-item {
    display: flex;
    width: 50%;

}



.app-title {
    display: flex;
    flex-direction: column;
    position: relative;
    color: #ea1d6f;
    font-size: 26px;
}

.app-title:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 25px;
    height: 4px;
    background: #ea1d6f;
}

.app-contact {
    margin-top: auto;
    font-size: 8px;
    color: #888;
}

.app-form-group {
    /* margin-bottom: 5px; */
    height: 55px;
}

.app-form-group.message {
    margin-top: 40px;
}

.app-form-group.buttons {
    margin-bottom: 0;
    text-align: right;
}

.app-form-control {
    width: 100%;
    padding: 10px 0;
    background: none;
    border: none;
    border-bottom: 1px solid #666;
    color: #ddd;
    font-size: 11px;
    text-transform: uppercase;
    outline: none;
    transition: border-color 0.2s;
}

.app-form-control::placeholder {
    color: #666;
}

.app-form-control:focus {
    border-bottom-color: #ddd;
}

.app-form-button {
    background: #1961ac;
    border: solid 2px blue;
    color: white;
    font-size: 12px;
    cursor: pointer;
    outline: none;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: 0px 4px 6px rgba(25, 97, 172, 1);


}
.headings{
    font-size: 30px;
    font-weight: 600;
}

.mainCon-cla {
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 150px;
}


.app-form {
    width: 60%;
    /* padding: 50px; */
}


.h2-text-con {
    font-size: 40px;
    font-weight: 600;
    text-align: center;
}


@media screen and (max-width: 520px) {
    .screen-body {
        flex-direction: column;
    }

    .screen-body-item.left {
        margin-bottom: 30px;
    }

    .app-title {
        flex-direction: row;
    }

    .app-title span {
        margin-right: 12px;
    }

    .app-title:after {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .screen-body {
        padding: 0px;
    }

    .screen-body-item {
        padding: 0;
        width: 100%;
    }

    .screen-body {
        display: flex;
        width: 100%;

    }
}

@media screen and (max-width: 520px) {

    .app-form {
        width: 100%;
    }

    .mainCon-cla {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 150px;
    }

}