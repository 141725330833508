.main-container {
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 100px;
    margin-bottom: 300px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

}

.blogimage {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    height: fit-content;
    /* padding: 10px; */
    border: solid 0.5px gray;
    background-color: #ffffff;
}

.blogimage-inside {
    width: 400px;

}

.p-text-blog {
    font-size: 18px;
    /* font-weight: 500; */
    word-break: break-all;
}

@media screen and (max-width: 768px) {

    .main-container {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 50px;
        margin-bottom: 50px;

    }


    .blogimage {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }


    .blogimage-inside {
        width: 100%;
        height: 200px;
    }


}

.btn-read {
    font-size: 16px;
    font-weight: 500;
    color: #19a9b2;
    letter-spacing: 0.5px;
}

/* Remove hover effect */
.btn-read:hover {
    pointer-events: none;
}

.blog-author {
    border-top: solid 1px rgb(96, 93, 93);
    width: 100%;
    display: flex;
    gap: 10px;
    padding-top: 20px;
    /* align-items: center; */
}

.blog-author-inside {
    border-radius: 100%;
    border: solid 2px #19a9b2;
    width: 100px;
    height: 100px;
}


.p-read {
    font-size: 16px;
    border-left: solid 2px gray;
    padding-left: 10px;

}

.p-read-se {
    font-size: 18px;
    border-top: solid 1px gray;
    word-break: break-all;
    margin: 0px;
    letter-spacing: 0.2px;
    position: relative;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.main-blog-con {
    width: 65%;
}


.main-container-blogs {
    margin-left: 150px;
    margin-right: 150px;
    margin-top: 50px;

}


.para-text-b {
    font-size: 19px;
    letter-spacing: 0.2px;
    word-spacing: 1.5px;
}

@media screen and (max-width: 768px) {
    .main-container-blogs {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 20px;
        display: flex;
        gap: 10px;
    }

    .main-container {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 10px;
        margin-bottom: 30px;
        display: flex;
        gap: 20px;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

    }

    .blogimage {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: fit-content;
        /* padding: 10px; */
        border: solid 0.5px gray;
        background-color: #ffffff;
    }

    .wraper-blogs {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }




}


@media screen and (max-width: 768px) {
    .main-blog-con {
        width: 100%;
        word-break: break-all;

    }



}



.main-blog-con-secend {
    width: 27%;
    margin-top: 60px;
    padding: 20px;
    height: fit-content;
    background-color: #f7f8fa;
}

@media screen and (max-width: 768px) {
    .main-blog-con-secend {
        width: 100%;
        margin-top: 60px;
        padding: 20px;

    }
}



.wraper-blogs {
    display: flex;
    justify-content: space-evenly;
}