.BreadArea-csr {
    height: 450px;
    color: #fff;
    padding: 100px 0;
    background-position: 90% 17%;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    font-family: 'Roboto', sans-serif;

}

.BreadArea-csr:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0000007a;
    z-index: 0;
    color: white;
}







.main-csr-box {
    margin-left: 130px;
    margin-right: 130px;
    display: flex;
    flex-direction: column;
    gap: 40px;

}

.inside-csr {
    background-color: #2a8df7;
    width: 100%;
    height: auto;
    /*margin-top: 100px;*/
    border-radius: 10px;
    position: relative;
    padding: 20px;
    color: white;
}
.pdf-clas1 {
    display: flex;
    align-content: center;
    gap: 10px;
    padding: 5px;
    background-color: white;
    border: solid 1px;
    border-color: gray;
    float: right;
    margin-left: 10px;
    width: 560px;
}
.p-text-csr {
    font-size: 19px;
    width: 700px;
    /* height: 300px; */
    /* margin-top: 20px; */
    /* padding: 20px; */
    word-break: break-all;
    word-spacing: 1px;

}

.inside-csr-2 {
    width: 300px;
    height: 400px;
    position: absolute;
    /* right: 100px; */
    top: -80px;
    border-radius: 10px;
    background-color: white;
    overflow: hidden;
}

.inside-csr-2-bt {
    width: 300px;
    height: 400px;
    position: absolute;
    right: 30px;
    top: -80px;
    border-radius: 10px;
    background-color: white;
    overflow: hidden;
    display: block;
}


.inside-csr-2-tb {
    width: 300px;
    height: 400px;
    position: absolute;
    right: 30px;
    top: -80px;
    border-radius: 10px;
    background-color: white;
    overflow: hidden;
}


.key-focus-inside-2 {

    width: 350px;
    height: 450px;
    border-radius: 10px;
    border: #1961ac solid 1px;
    /* padding: 20px; */
    overflow: hidden;


}

.key-focus-inside-2-t {
    width: 350px;
    height: fit-content;
    border-radius: 10px;
    border: #1961ac solid 1px;
    /* padding: 20px; */
    overflow: hidden;
}

.key-focus {
    display: flex;
    justify-content: space-between;
}

.key-focus-inside {
    width: 90%;
    height: fit-content;
    border-radius: 10px;
    border: #1961ac solid 1px;
    /* padding: 20px; */
    overflow: hidden;

}


.secend-mobi {
    display: none;
}

.inside-csr-mobi {
    /* height: 300px;as */
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 10px;

}


.swith-bt {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 20px;
    margin-top: 80px;
    padding-bottom: 50px;

}

.blue-text {
    background-color: #2a8df7;
    color: white;
    padding: 10px;
    border-radius: 10px;

}

.swith-bt-t {
    cursor: pointer;

}

.ano-txt {
    background-color: #2a8df7;
    color: #fff;
    width: 100%;
    /* height: 300px; */
    border-radius: 10px;
    display: flex;
    padding: 20px;
    gap: 20px;
    align-items: center;
}

.key-focus-inside-2-t-over {
    /* width: 400px; */
    height: fit-content;
    border-radius: 10px;
    border: gray solid 1px;
    /* padding: 20px; */
    overflow: hidden;
    display: block;

}

.p-text-csr-1 {
    font-size: 19px;
    word-spacing: 1px;
    /*width: 900px;*/
    margin-top: 20px;
    /* padding: 20px; */
    /*word-break: break-all;*/

}


@media screen and (max-width: 768px) {
    .inside-csr-2-bt {

        display: none;
    }

    .main-csr-box {
        margin-top: 0px;
        margin-left: 10px;
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;

    }

    .inside-csr {
        background-color: #2a8df7;
        width: 100%;
        height: fit-content;
        margin-top: 10px;
        border-radius: 10px;
        position: relative;
        padding: 20px;
        color: white;
    }

    .p-text-csr-1 {
        font-size: 16px;
        word-spacing: 1px;
        width: 100%;
        margin-top: 10px;
        padding: 0px;
        word-break: break-all;

    }

    .ano-txt {
        background-color: #2a8df7;
        color: #fff;
        width: 100%;
        /* height: 300px; */
        border-radius: 10px;
        display: flex;
        padding: 0px;
        gap: 20px;
        align-items: center;
    }

    .p-text-csr {
        font-size: 19px;
        width: 100%;
        /* height: 300px; */
        /* margin-top: 20px; */
        /* padding: 20px; */
        word-break: break-all;
        word-spacing: 1px;

    }

    .key-focus-inside-2-t-over {
        display: none;

    }

    .p-text-csr {
        font-size: 16px;
        width: 100%;
        padding: 10px;
        word-break: break-all;
        word-spacing: 1px;

    }
    

}