.director_container_mission {
    display: flex;
    gap: 70px;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    margin-top: 30px;
}

@media only screen and (max-width: 768px) {
    .director_container_mission {
        flex-direction: column;
    }

    .additional_style_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 800px;
        height: fit-content;
        margin: auto;
        margin-bottom: 40px;
        text-align: center;
    }

}






.additional_style_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    height: fit-content;
    margin: auto;
    margin-bottom: 40px;
}

.new_container_style_mission {
    width: 360px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #f6f5fa;
    border-radius: 20px;
    margin: auto;
    position: relative;
}

.ceo_heading {
    margin: 0;
    width: 360px;
    height: 65px;
    color: white;
    background-color: rgba(21, 160, 162, 0.7);
    position: absolute;
    bottom: 0;
    text-align: center;
}


.container-style-mission_directer {
    width: 360px;
    height: 360px;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #f6f5fa;
    border-radius: 20px;
    /* margin: auto; */
    position: relative;
}

.directors_heading_mission {
    display: flex;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}




/* ================================================ */

.main-lead-team-switch {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

@media only screen and (max-width: 768px) {
    .main-lead-team-switch {
        display: none;
    }
}

.lead-team-switch {
    display: flex;
    gap: 100px;
    padding: 10px;
    width: fit-content;
    align-items: center;
    border-radius: 10px;
    color: white;
    background-color: #0e5454;
    height: 50px;
    padding-left: 40px;
    padding-right: 40px;
    cursor: pointer;

}


.swiper-card-box {
    width: 320px;
    height: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;

}

.image-circle-box {
    width: 320px;
    height: 320px;
    border-radius: 100%;
    border: 3px solid #0dcaf0;
    object-fit: cover;
}

.main-lead-team-switch-mobile {
    display: none;
}

@media only screen and (max-width: 600px) {
    .main-lead-team-switch-mobile {
        display: block;
    }

}





/* ========================pop up======== */

.popup-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.popup {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    width: 900px;
    position: relative;
}

.popup-content {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.popup button {
    margin-top: 10px;
}



.dir_heading_text-t {
    font-size: 22px;
    color: #2cb1bc;
}

@media only screen and (max-width: 600px) {
    .dir_heading_text-t {
        font-size: 20px;
    }
}


.dir_para_text-t {
    font-size: 16px;
    word-spacing: 2px;
}

@media only screen and (max-width: 768px) {
    .dir_para_text-t {

        font-size: 14px;
    }
}


.hover-underline-animation {
    display: inline-block;
    position: relative;
    color: white;
    padding-top: 10px;
}

.hover-underline-animation:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: white;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}



.main-mission-text {
    /* width: 50%; */
    text-align: center;
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 20px;
    /* background-color: rgba(13, 127, 137, 0.8); */
    background-color: #1961ac;
    color: white;
    margin-top: 40px;
}

.main-mission-text-2 {
    height: 517px;
    /* text-align: center; */
    /* padding: 20px; */
    margin-top: 20px;
    justify-content: center;
    width: 50%;
    margin-top: 40px;


}

.inside-misi-val {
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    padding: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;


}

.main-section-box-container {
    display: flex;
    gap: 20px;
    margin-left: 100px;
    margin-right: 100px;
    /*word-break: break-all;*/
    align-items: center;
}

.p-text-mission-v {
    font-size: 18px;
    word-spacing: 2px;
}

.inside-mi-vi-box {
    width: 50%;
}

.image-box-mi {
    width: 300px;
    height: 200px;
}

@media only screen and (max-width: 768px) {
    .main-section-box-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-left: 10px;
        margin-right: 10px;
        word-break: break-all;
        align-items: center;
    }

    .inside-mi-vi-box {
        width: 100%;
    }

    .image-box-mi {
        width: 100%;
        height: 70px;
    }

    .p-text-mission-v {
        font-size: 15px;
        word-spacing: 2px;
    }


    .main-mission-text-2 {
        height: fit-content;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
    }


}