.main-navbar-container {
    display: flex;
    justify-content: center;
}


@media screen and (max-width: 768px) {

    .main-navbar-container {
        display: none;
        /* color: #16646b; */
    }


}




.nav-link {
    display: block;
    position: relative;
    text-decoration: none;
}

.nav-link:hover {
    border-bottom: 0px;
    color: #fff;
}

.nav-link:after {
    content: "";
    /* height: 100%; */
    left: 0;
    bottom: 0;
    width: 0px;
    position: absolute;
    transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    z-index: -1;
}



.nav-link:after {
    background: #1961ac;
}