.main_feedback {
    display: flex;
    justify-content: space-between;

}

.feedback-sec {
    margin-left: 100px;
    margin-right: 100px;
}

.main-head-sec-feed {
    background-color: #2e93eb;
    padding-top: 100px;
    padding-bottom: 100px;
}

/*.feedback_card {
    
    height: 300px;
    border: 1px solid #ccc;
    
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    
    padding: 20px;
    border-radius: 10px;
    background-color: white;
}*/
.feedback_card{
  display:flex;
  background-color:#fff;
  padding:20px;
  border-radius:30px;
  min-width:40px;
  max-width:300px;
  min-height:40px;
  margin:20px;
  position:relative;
  align-items:center;
  justify-content:center;
  text-align:center;
}
.feedback_card:before,
.feedback_card:after {
  content:"";
  background-color:#fff;
  border-radius:50%;
  display:block;
  position:absolute;
  z-index:-1;
}
.feedback_card:before {
  width:44px;
  height:44px;
  top:-12px;
  left:28px;
  box-shadow:-50px 30px 0 -12px #fff;
}
.feedback_card:after {
  bottom:-10px;
  right:26px;
  width:30px;
  height:30px;
  box-shadow:40px -34px 0 0 #fff,
             -28px -6px 0 -2px #fff,
             -24px 17px 0 -6px #fff,
             -5px 25px 0 -10px #fff;
  
}
/* @media (max-width: 767px) {
    .main_feedback {
        display: none;
    }
} */

.h2-text-client {
    font-size: 40px;
    font-weight: 700;
    padding-bottom: 50px;
}

.main_feedback_mobile {

    display: none;
}


@media (max-width: 767px) {
    .main_feedback_mobile {
        display: flex;
    }
}

@media (max-width: 767px) {
    .feedback_card {
        width: 100%;
        margin-top: 20px;
    }


    .feedback-sec {
        margin-left: 10px;
        margin-right: 10px;
    }


    .main-head-sec-feed {
        background-color: #2e93eb;
        padding-top: 30px;
        padding-bottom: 30px;
    }


    .h2-text-client {
        font-size: 40px;
        font-weight: 700;
        padding-bottom: 10px;
    }

}