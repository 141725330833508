.main-team-box {
    padding: 50px 100px 100px 100px;
    font-family: 'Roboto', sans-serif;
    /* background: rgba(25, 97, 172, 0.5); */
    background-color: #1961ac;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-top: 100px; */

}

.image-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
}

.image-box-team {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #1961ac;
}

.p-text-team {
    color: #1961ac;
    font-weight: 600;
    font-size: 18px;
    padding-top: 10px;
    letter-spacing: 0.2px;
    word-spacing: 2px;
}






.main-team-box-inside {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: 20px;
    /* padding-top: 50px; */
    /* margin-left: '100px'; */
    background-color: rgb(255, 255, 255, 1);
    padding: 20px;
    border-radius: 20px;
}

@media only screen and (max-width: 768px) {
    .main-team-box {
        padding: 10px 10px 10px 10px;
        font-family: 'Roboto', sans-serif;
        background: rgba(25, 97, 172, 0.5);

    }

    .image-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

}



.pop-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.pop-box-team {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 450px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    padding: 15px;
    /* color: white; */
    z-index: 9999;
    overflow-y: auto;


}

.close-btn {
    background-color: #1961ac;
    color: white;
    border: none;
    outline: none;
    padding: 3px, 3px, 3px, 3px;
    display: flex;
    justify-content: center;
    align-items: end;
    margin-top: 10px;
}


.close-icon {
    font-size: 25px;
    color: black;
    position: fixed;
    right: 10px;
    top: 7px;
}

.p-sub-heading {
    font-size: 18px;
    word-spacing: 2px;
    letter-spacing: 0.2px;

}

.p-text-team-para {
    margin-top: 10px;
    font-size: 18px;
    color: rgb(124, 127, 131);
    /* padding: 1px; */
    word-spacing: 2px;
    /*word-break: break-all;*/
    /* letter-spacing: 0.2px; */
    /* border-bottom: solid 1px #17a9b5; */

}

.pop-box-team::-webkit-scrollbar {
    width: 8px;
    /* Set the width of the scrollbar */
}

.pop-box-team::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Set the color of the scrollbar thumb */
    border-radius: 4px;
    /* Set the border radius of the thumb */
}

.pop-box-team::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Set the color of the scrollbar track */
}



@media only screen and (max-width: 768px) {
    .pop-box-team {
        /* position: fixed; */
        /* top: 50%; */
        /* left: 50%; */
        transform: translate(-50%, -50%);
        width: 90%;
        /* margin: 10px; */
        height: 400px;
        background-color: rgb(255, 255, 255);
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.9);
        padding: 10px;
        /* color: white; */
        z-index: 9999;
        overflow-y: auto;


    }

}