.main-bussiness {
    font-family: 'Roboto', sans-serif;
    /* background: linear-gradient(to right, #dff5f2, #ebe2d9); */
    /* background: linear-gradient(to right, #b2e7e2, #fcf0e4); */


    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 100px;
    padding-right: 100px;


}

.h2-text-b {
    font-weight: 700;
    word-spacing: 2px;
    font-size: 40px;
    text-align: center;
    letter-spacing: 0.2px;

}

.p-text-b {
    font-size: 18px;
    word-spacing: 2px;
}

@media only screen and (max-width: 768px) {

    .main-bussiness {
        font-family: 'Roboto', sans-serif;
        background: linear-gradient(to right, #dff5f2, #ebe2d9);
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;


    }

}

.business-parners-box {
    display: flex;
    /* Use flex container */
    flex-wrap: wrap;
    justify-content: center;
    background-color: white;
    border-radius: 10px;
}

.image-box-busi {
    width: 120px;
    height: auto;
    /* Maintain aspect ratio */
    margin: 10px;
    display: flex;
    align-items: center;
    padding: 10px;
}