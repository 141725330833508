.table-d-pro {
    border: 1px solid #0d5654;
    width: 50%;
    padding: 20px;
    height: fit-content;
}

.table-head {
    padding: 5px;
    border: 2px solid#0d5654;
    font-size: 15px;
}

/* .td-text-pro {
    background-color: rgb(247, 148, 51, 0.3);
} */

.td-text-pro:hover {
    background-color: #0d5654;
    color: white;
    transition: ease-in-out 0.2s;
}

.main-promoter-container {
    padding-top: 50px;
    padding-left: 100px;
    padding-right: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;

}

.p-text {
    font-size: 18px;
    word-spacing: 2px;
    /*word-break: break-all;*/
    letter-spacing: 0.1px;


}

.main-focus-box {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    margin-right: 100px;
    margin-left: 100px;
    padding-top: 60px;
    padding-bottom: 50px;
    background-color: #dedee2;
    padding: 20px;
    margin-bottom: 50px;
    margin-top: 50px;



}

.main-focus-box-inside {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    padding-bottom: 30px;
}

.main-focus-box-inside-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
}


.div-pri {
    width: 48%;
    background-color: white;
    padding: 20px;
}

.div-pri-2 {
    width: 48%;
    background-color: white;
    padding: 20px;
}

.content-box-promo {
    width: 50%;
}


@media only screen and (max-width: 768px) {
    .main-promoter-container {
        padding-top: 20px;
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        flex-direction: column;

    }

    .content-box-promo {
        width: 100%;
    }

    .table-d-pro {
        border: 1px solid #0d5654;
        width: 100%;
        padding: 0px;
        height: fit-content;
    }

    .table-head {
        padding: 5px;
        border: 2px solid#0d5654;
        font-size: 15px;
    }

    .main-focus-box {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px;


    }

    .main-focus-box-inside {
        display: flex;
        flex-direction: column;
        padding-bottom: 30px;
    }

    .p-text {
        font-size: 15px;
        word-spacing: 2px;
        text-align: left;
        word-break: break-all;

    }


}

.focus-inside {
    width: 55%;
}

.focus-inside-2 {
    width: 40%;
}



@media only screen and (max-width: 768px) {


    .focus-inside {
        width: fit-content;
    }

    .focus-inside-2 {
        width: 90%;
    }
}