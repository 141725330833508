.main-mini-container {
    display: flex;
    margin-bottom: 120px;
    margin-left: 100px;
    margin-right: 100px;
    gap: 70px;
    margin-top: 50px;
    justify-content: center;
    align-items: center;
}

.mini-about-text {
    /* margin-left: 70px; */
    font-size: 20px;
    /* margin-right: 40px; */
    word-spacing: 3px;
    font-family: 'Roboto', sans-serif;
}

.mini-about-container {
    width: fit-content;
    height: 664px;
    display: flex;
    background-color: #f4881f;
    padding: 10px;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    /* margin-right: 70px; */
    margin-top: -100px;
    z-index: 1;
}

.inside-mini {
    display: flex;
    flex-direction: column;
}

.deetails-box {
    width: 290px;
    height: 280px;
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, 0.7);
    /* background-color: rgba(128, 128, 128, 0.3); */
    background-color: #f7f8fa;



}




.p-mini {
    /* color: '#7c7f83'; */
   /* word-break: break-all;*/

}

.mini-about {
    width: 50%;
}

.inside-mini-main {
    width: 50%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 30px;

}



@media screen and (max-width: 768px) {


    .main-mini-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 120px;
        margin-left: 0px;
        margin-right: 0px;
        padding: 10px;
        margin-top: 10px;
        justify-content: center;
        align-items: center;
    }

    .mini-about {
        width: 100%;
    }


    .deetails-box,
    .deetails-box2,
    .deetails-box3,
    .deetails-box4 {
        width: 177px;
        height: 330px;
    }


    .inside-mini-main {
        display: none;
    }


}






@media screen and (max-width: 768px) {


    .p-mini {
        font-size: 15px;
    }


}


.banner-title {
    color: black;
    font-size: 18px;
    overflow: hidden;
    white-space: wrap;
    text-overflow: ellipsis;
    /*word-break: break-all;*/
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    letter-spacing: 0.2px;
}

.inside-mini-main-mobile {
    display: block;
}