.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-out;
}

.main-header-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.swiper-container {
    width: 100%;
    height: 500px;
}

.image-container {
    width: 100%;
    height: 500px;
    object-fit: cover;
    background-color: rgba(0, 0, 0, 1);
    /* opacity: 0.9; */
}

.nav-buttons-cl {
    position: absolute;
    bottom: 20px;
    left: 50px;
    z-index: 1;
    display: block;
}

@media screen and (max-width: 768px) {
    .swiper-container {
        width: 100%;
        height: fit-content;
    }


    .nav-buttons-cl {
        display: none;
    }

    .image-container {
        width: 100%;
        height: 230px;
        object-fit: cover;
        object-position: center;
        /* position: relative; */
    }





}


.h2-text-header {
    font-size: 65px;
    font-weight: 600;
    position: absolute;
    top: 200px;
    left: 100px;
    color: white;
    top: 50%;
    left: 50%;
}

@media screen and (max-width: 768px) {

    .h2-text-header {
        font-size: 35px;
        font-weight: 600;
        position: absolute;
        top: 150px;
        left: 135px;
        color: white;
        /* top: 50%; */
        /* left: 50%; */
    }
}